<template>
  <div>
    <div class="modal fade" tabindex="-1" role="dialog" :class="{ 'show': isOpen }">
      <transition name="fade">
        <div v-if="isOpen">
          <div class="modal-backdrop fade show" @click="closeOnBackdropClick"></div>
        </div>
      </transition>

      <transition name="fade-slide">
        <div v-if="isOpen">
          <slot name="modal"></slot>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
  const KEY_ESCAPE = 27

  export default {
    props: {
      openOnInit: {
        type: Boolean,
        default: false
      },
      backdropClickToCloseEnabled: {
        type: Boolean,
        default: true
      },
      escapeToCloseEnabled: {
        type: Boolean,
        default: true
      },
      openEvent: {
        type: String,
        default: "openModal"
      },
      closeEvent: {
        type: String,
        default: "closeModal"
      },
      openCallback: {
        type: Function,
        default () {}
      },
      closeCallback: {
        type: Function,
        default () {}
      }
    },
    data() {
      return {
        keyUpEventBound: false,
        isOpen: false
      }
    },
    methods: {
      open() {
        this.addBodyClass()
        this.addKeyUpEvent()

        this.isOpen = true

        this.openCallback()
      },
      close() {
        this.removeKeyUpEvent()
        this.removeBodyClass()

        this.isOpen = false

        this.closeCallback()
      },
      closeOnBackdropClick() {
        if(this.backdropClickToCloseEnabled) {
          this.close()
        }
      },
      closeOnEscape(event) {
        if(this.escapeToCloseEnabled && this.isOpen && event.keyCode == KEY_ESCAPE) {
          this.close()
        }
      },
      addBodyClass() {
        let body = document.getElementsByTagName("body")[0]
        body.classList.add("modal-open")
      },
      removeBodyClass() {
        let body = document.getElementsByTagName("body")[0]
        body.classList.remove("modal-open")
      },
      addKeyUpEvent() {
        if(!this.keyUpEventBound && this.escapeToCloseEnabled) {
          document.addEventListener("keyup", this.closeOnEscape)

          this.keyUpEventBound = true
        }
      },
      removeKeyUpEvent() {
        if(this.keyUpEventBound) {
          document.removeEventListener("keyup", this.closeOnEscape)

          this.keyUpEventBound = false
        }
      }
    },
    created() {
      window.eventHub.$on(`${this.openEvent}`, this.open)
      window.eventHub.$on(`${this.closeEvent}`, this.close)
    },
    mounted() {
      if(this.openOnInit) {
        this.open()
      }
    },
    destroyed() {
      this.removeKeyUpEvent()
      this.removeBodyClass()

      window.eventHub.$off(`${this.openEvent}`, this.open)
      window.eventHub.$off(`${this.closeEvent}`, this.close)
    }
  }
</script>

<style lang="scss" scoped>
  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .modal.show {
    display: block;
  }

  .modal .modal-backdrop {
    z-index: 0;
  }

  .fade-slide-enter-active, .fade-slide-leave-active {
    transition: all 0.2s ease;
    transform: translate(0, 0);
    opacity: 1;
  }
  .fade-slide-enter, .fade-slide-leave-to {
    transform: translate(0, -25%);
    opacity: 0;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.2s linear;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>